import styled from 'styled-components';

export const AddToCartButton = styled.button`
    position: relative;
    display: block;
    text-align: center;
    font-size: 18px;
    ${({ backgroundColor }) => {
      const bgColor =
        backgroundColor && backgroundColor !== '' ? backgroundColor : '#e26447';
      return `
        background-color: ${bgColor};
        `;
    }}
    width: 100%;
    padding: 18px 0;
    border-radius: 30px;
    color: #fff;
    text-decoration: none;
    line-height: 18px;
    vertical-align: middle;
    -webkit-transition: all .2s;
    transition: all .2s;
    border: 0;
    cursor: pointer;
    outline:none;

    &:after {
        content: "";
        background-image: url("/images/cart-white.svg");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        padding-top: 20px;
        right: 30px;
        top: 17px;
        font-weight: 100;
        position: absolute;
        color: #fff;
        width: 22px;
        -webkit-transition: .1s linear;
        transition: .1s linear;
    }

    &:active, &:focus {
        border: 0;
    }

    &:hover {
        ${({ backgroundColorHover }) => {
          const bgColor =
            backgroundColorHover && backgroundColorHover !== ''
              ? backgroundColorHover
              : '#C34D32';
          return `
            background: ${bgColor};
            `;
        }}
        & > span {
            top: 2px;
        }
        &:after {
            right: 35px;
        }
    }
    & span {
        position: relative;
        width: 130px;
        display: block;
        margin: auto;
        font-weight: bold;
    }

    &.processing {
        ${({ backgroundColorHover }) => {
          const bgColor =
            backgroundColorHover && backgroundColorHover !== ''
              ? backgroundColorHover
              : '#C34D32';
          return `
            background: ${bgColor};
            `;
        }}
        & > span {
            top: 0px;
        }
        &:after {
            visibility: hidden;
        }
        
        &:before {
            content: "";
            height: 22px;
            width: 100%;
            background-image: url("/images/three-dots.svg");
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
            display: block;
        }
        & > span {
            display: none;
        }
    }

    ${({ pageDesign }) => {
      if (pageDesign === `design2`) {
        return `
          background-color: #BC2E3E;
          -webkit-box-shadow: 0 4px 0 #961423;
          box-shadow: 0 4px 0 #961423;
          &:hover {
            background-color: #961423;
          }
          & > span {
            font-size: 22px;
            font-weight: bold;
            text-transform: capitalize;
            width: 180px;
          }
          `;
      }
    }}
}
`;
